import NewNavbar from "../../../../Components/NewNavbar/NewNavbar";
import {
	COLORS,
	COLORS_RGBA, LOADING_GIF,
	NAV_CLASS,
	TEXT_COLOR,
} from '../../../../Configs/Targets';
import * as Icon from "react-bootstrap-icons"
import {Form, OverlayTrigger, Popover} from "react-bootstrap";
import NewShoppingItems from "../../../../Components/NewShoppingItems/NewShoppingItems";
import {useDispatch, useSelector} from 'react-redux';
import {useWindowSize} from '../../../../CustomFunctions/useWindowSize';
import {useEffect, useState} from 'react';
import {getHostname} from '../../../../CustomFunctions/getHostname';
import {SCREEN_SIZE, SHOPIFY} from '../../../../Configs/Constants';
import {graphql} from '../../../../Axios/graphql';
import {ShopifyGraphQlQueries} from '../../../../Configs/ShopifyGraphQlQueries';
import {
	setCartItems,
	setGrandTotal,
} from '../../../../redux/actions/CartActions';
import {NavLink} from 'react-router-dom';
import NewMiniCart from '../../../../Components/NewMiniCart/NewMiniCart';
import {GetConvertedPrice} from '../../../../CustomFunctions/getConvertedPrice';

const NewShopBag = () => {

	// Hooks
	const cart = useSelector((state) => state);
	const dispatch = useDispatch();
	let windowSize = useWindowSize();

	const store = useSelector(state=>state)
	/*States*/
	let [subtotal, setSubtotal] = useState(0);
	let [discount, setDiscount] = useState(false);
	let [discountType, setDiscountType] = useState(null);
	let [discountValue, setDiscountValue] = useState(null);
	let [discountCode, setDiscountCode] = useState(null);
	let [shopifyCart, setShopifyCart] = useState(null);
	let [loading, setLoading] = useState(true);





	/*States Ends*/

	/*Variables Start*/
	const hostName = window.location.hostname;
	const domainObject = getHostname(hostName);
	const target = domainObject.host;
	let bgColor = NAV_CLASS[target], textColor = TEXT_COLOR[target], color = COLORS[target],
			colorRgba = COLORS_RGBA[target], topBannerStyle = {
				width: '100%',
				height: '144px',
				background: `linear-gradient(to bottom, ${colorRgba}0.71) 45%, ${colorRgba}1)), url(${require('../../../../assets/images/shop_top_banner.png')})`,
				backgroundSize: 'cover'
			}, loadingGif = LOADING_GIF[target];
	let isMobile = false;
	if (windowSize.width <= SCREEN_SIZE.MOBILE) {
		isMobile = true;
	}
	/*Variables Ends*/

	/* Use Effect Start */
	useEffect(() => {
		setLoading(true);
		getShopifyCart();
	}, []);
	/* Use Effect End */

	/*API Call*/
	/*API Call Ends*/

	/*Event Methods*/

	const getShopifyCart = () => {
		let checkoutId = localStorage.getItem("checkoutId");
		let data = {checkout_id: checkoutId};
		graphql(ShopifyGraphQlQueries(SHOPIFY.GET_SHOPIFY_CART, null, null, data))
				.then((response) => {
					let tempCartItems = [];
					if (typeof response.data.data !== 'undefined') {
						response.data.data.node.lineItems.edges.map((item) => {
							let tempVariant = {
								id: item.node.variant.id,
								title: item.node.variant.title,
								price: item.node.variant.price?.amount,
								image: item.node.variant.image,
							};
							let node = {
								id: item.node.variant.product.id,
								product_name: item.node.title,
								variant: tempVariant,
								size: item.node.variant.selectedOptions[0].value,
								color: item.node.variant.selectedOptions[1] ? item.node.variant.selectedOptions[1].value : "",
								lineItemId: item.node.id,
								quantity: item.node.quantity,
							};
							tempCartItems.push(node);

						});
						if (response.data.data.node.discountApplications.edges.length === 0) {
							setSubtotal(Math.round(parseFloat(response.data.data.node.subtotalPrice?.amount) * 100) / 100);
						} else {
							let tempSubtotal = 0;
							setDiscountCode(response.data.data.node.discountApplications.edges[0].node.code);
							setDiscountValue(response.data.data.node.discountApplications.edges[0].node.value.percentage);
							setDiscount(true);
							response.data.data.node.lineItems.edges.map((item) => {
								tempSubtotal += parseFloat(item.node.variant.price?.amount);
							})
							setSubtotal(tempSubtotal);
						}
						dispatch(setCartItems(tempCartItems));
						let total = Math.round(response.data.data.node.subtotalPrice?.amount * 100) / 100;
						dispatch(setGrandTotal(total));
						setShopifyCart(response.data.data.node);
						if (response.data.data.node.discountApplications.edges.length === 0) {
							setSubtotal(parseFloat(response.data.data.node.subtotalPrice?.amount));
						}
					}
					else {
						document.getElementById('navbarShop').click();
						document.getElementById('navbarShop').click();
					}
					setLoading(false);
				})
				.catch((err) => {
					console.error(err);
				})
	}

	const handlePromoDiscount = () => {
		setLoading(true);
		let discountCode = document.getElementById('applyDiscountCodeCheckout').value;
		let checkoutId = localStorage.getItem("checkoutId");
		let data = {checkout_id: checkoutId, discount_code: discountCode};
		graphql(ShopifyGraphQlQueries(SHOPIFY.APPLY_DISCOUNT_CODE, null, null, data))
				.then((response) => {
					let edges = response.data.data.checkoutDiscountCodeApply.checkout.discountApplications.edges
					if (edges.length > 0) {
						localStorage.removeItem("checkoutUrl");
						localStorage.setItem('checkoutUrl', response.data.data.checkoutDiscountCodeApply.checkout.webUrl);
						let discount = edges[0].node.value;
						setDiscountType(discount.__typename);
						setDiscountValue(discount.percentage);
						let total = subtotal - (subtotal * (discount.percentage / 100));
						setGrandTotal(Math.round(total * 100) / 100);
						setDiscount(true);
						setDiscountCode(edges[0].node.code);
						getShopifyCart();
					}
				})
				.catch((err) => {
					console.error(err);
				})
	}

	const removePromoDiscount = () => {
		setLoading(true);
		let checkoutId = localStorage.getItem("checkoutId");
		let data = {checkout_id: checkoutId}
		graphql(ShopifyGraphQlQueries(SHOPIFY.REMOVE_DISCOUNT_CODE, null, null, data))
				.then((response) => {
					getShopifyCart();
				})
				.catch((err) => {
					console.error(err);
				})
		setDiscount(false);
		document.getElementById('RemoveDiscountCodeCheckout').value = "";
		setGrandTotal(subtotal);
	}

	const handleCheckout = () => {
		let checkoutUrl = localStorage.getItem("checkoutUrl");
		localStorage.removeItem('checkoutUrl')
		localStorage.removeItem('checkoutId')
		window.location.href = checkoutUrl;
	}
	/*Event Methods Ends*/

	/*item event methods*/
	const incrementQuantity = (event) => {
		setLoading(true);
		let variantId = event.currentTarget.dataset.id;
		let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === variantId);
		let quantity = currentItem[0].node.quantity;
		let tempQuantity = quantity + 1;
		let checkoutId = localStorage.getItem("checkoutId");

	}

	const decrementQuantity = (event) => {
		setLoading(true);
		let variantId = event.currentTarget.dataset.id;
		let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === variantId);
		let quantity = currentItem[0].node.quantity;
		let price = parseFloat(currentItem[0].node.variant.price?.amount);
		let tempQuantity = quantity - 1;
		if (tempQuantity >= 1) {
			let checkoutId = localStorage.getItem("checkoutId");
			let data = {checkout_id: checkoutId, variant_quantity: tempQuantity, linkItem_id: currentItem[0].node.id};
			graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_UPDATE, null, null, data))
					.then((response) => {
						localStorage.removeItem("checkoutUrl");
						localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsUpdate.checkout.webUrl);
						setSubtotal(subtotal - parseFloat(currentItem[0].node.variant.price?.amount));
						getShopifyCart();
					})
					.catch((err) => {
						console.error(err);
					})
			// dispatch(setGrandTotal(parseFloat(cart.cart.grandTotal) - Math.round((price * parseInt(tempQuantity)) * 100) / 100));
		}
	}

	const removeItem = (id) => {
		setLoading(true);
		let currentItem = shopifyCart.lineItems.edges.filter((item) => item.node.variant.id === id);
		let checkoutId = localStorage.getItem("checkoutId");
		let data = {checkout_id: checkoutId, lineItem_id: currentItem[0].node.id};
		graphql(ShopifyGraphQlQueries(SHOPIFY.CHECKOUT_LINE_ITEMS_REMOVE, null, null, data))
				.then((response) => {
					localStorage.removeItem("checkoutUrl");
					localStorage.setItem('checkoutUrl', response.data.data.checkoutLineItemsRemove.checkout.webUrl);
					getShopifyCart();
				})
				.catch((err) => {
					console.error(err);
				})
		let cartAfterFilter = cart.cart.cartItems.filter((item, index) => item.variant.id !== id);
		if (cartAfterFilter.length > 0) {
			dispatch(setCartItems(cartAfterFilter));

		} else {
			getShopifyCart();
			dispatch(setCartItems(cartAfterFilter));
			dispatch(setGrandTotal(0));
			document.getElementById('navbarShop').click();
			document.getElementById('navbarShop').click();
		}


	}

	const renderSummaryAndCheckout = (data,handlePromoDiscount,removePromoDiscount)=> {



		return(
			<div className={"col-sm-12 col-xs-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3"}>
				<span className={"sfProDisplayFont font-20 text-dark fw-bold"}>Summary</span>
				<hr/>
				{data?.discountApplications?.edges?.length ?
						<div className={"d-flex justify-content-between"}>
							<input
									id={`RemoveDiscountCodeCheckout`}
									value={discountCode}
									readOnly
									disabled
									style={{
										appearance: "none",
										border: "1px solid #c0c0c0",
										background: "gray",
										padding: "12px",
										borderRadius: "7px",
										fontSize: "14px",
									}}
									placeholder={"Enter Discount Code"}
							/>
							<button
									onClick={removePromoDiscount}
									style={{
										width: "123px",
										height: "46px",
										border: "1px solid #c0c0c0",
										borderRadius: "6px"
									}}>
								Remove
							</button>
						</div>

						:
						<div className={"d-flex justify-content-between"}>
							<input
									id={`applyDiscountCodeCheckout`}
									style={{
										appearance: "none",
										border: "1px solid #c0c0c0",
										background: "#f2f2f2",
										padding: "12px",
										borderRadius: "7px",
										fontSize: "14px",
									}}
									placeholder={"Enter Discount Code"}
							/>
							<button
									onClick={handlePromoDiscount}
									style={{
										width: "123px",
										height: "46px",
										border: "1px solid #c0c0c0",
										borderRadius: "6px"
									}}>
								Apply
							</button>
						</div>


				}
				<hr/>
				<div className={"d-flex justify-content-between"}>
					<div className={"d-flex flex-column"}>
						<span className={"sfProDisplayFont font-20 text-dark fw-bold"}>Subtotal:</span>
						<span className={"sfProDisplayFont font-16"} style={{color: "#757273"}}>Tax included </span>
						<span className={"sfProDisplayFont font-16"} style={{color: "#757273"}}>Shipping is calculated at checkout</span>
					</div>
					<div>
						<span className={"sfProDisplayFont font-20 text-dark fw-bold"}>
							{/*{GetConvertedPrice(data?.subtotalPrice,store)}*/}
							{console.log('data =', data?.subtotalPrice?.amount)}
							£{data?.subtotalPrice?.amount}
						</span>
					</div>
				</div>
				<hr/>
				<div className={"d-flex flex-column"}>
					<button className={"py-2 text-white sfProDisplayFont"}
									onClick={handleCheckout}
					   style={{
							border: "1px solid #c0c0c0",
						  borderRadius: "6px",
						   backgroundColor: COLORS[target]
						}}
					>
						Checkout Securely
					</button>
					<NavLink to={'/shop'} className={"py-2 text-decoration-none text-black text-center sfProDisplayFont mt-3"}
					        style={{
						        border: "1px solid #c0c0c0",
						        borderRadius: "6px",
						        backgroundColor: "#c0c0c0"
					        }}
					>
						Continue Shopping
					</NavLink>
				</div>
			</div>
		)
	}

	return(
		<>
			<NewNavbar/>
			<div className={"container mt-5"}>
				<div className={"d-flex mt-3 justify-content-center"}>
					<span className={"text-dark bebasFont font-60"}>YOUR BAG</span>
				</div>
				<div className={"row mt-2 mb-5"}>
						<div className={"offset-2 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5"}>
							<div className={"row"}>
								<div className={"col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6"}>
									{
										shopifyCart?.lineItems?.edges.map((shoppingItems, index) => {
											return(
												<NewShoppingItems item={shoppingItems} store={store} removeItem={removeItem} key={index} />
											)
										})
									}
								</div>
								{renderSummaryAndCheckout(shopifyCart,handlePromoDiscount,removePromoDiscount)}
							</div>
						</div>
				</div>
			</div>
		</>
	)
}

export default NewShopBag;
