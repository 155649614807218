import {Link, useLocation, useSearchParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../Configs/Constants';
import {useSelector} from 'react-redux';
import {COLORS, FONT_COLOR, TEAM_NAME, TEXT_COLOR} from "../../Configs/Targets";
import {getHostname} from "../../CustomFunctions/getHostname";
import MoreStories from "../../Components/Layout/MoreStories";
import LatestNews from '../../Components/Layout/LatestSection/LatestNews';
import NewsOnSlider from '../../Components/Slider/NewsOnSlider';
import {get} from '../../Axios/get';
import {FOOTBALL} from '../../Axios/EndPoints';
import moment from 'moment-timezone';
import {chunk} from '../../Components/Helper/chunk';

const NewsAndSocials = () => {

    const store = useSelector((state) => state);
    let queryParams = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();
    const [teamType, setTeamType] = useState('team')

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;

    const [clubDetails, setClubDetails] = useState(null);
    const [leagueId, setLeagueId] = useState(parseInt(searchParams.get('league_id') || '0'));
    const [page, setPage] = useState(1);
    const [isMobile, setIsMobile] = useState(false);
    const [latestNews, setLatestNews] = useState(null);
    const [transferNews, setTransferNews] = useState(null);
    const [latestNewsLoading, setLatestNewsLoading] = useState(false);
    const [transferNewsLoading, setTransferNewsLoading] = useState(false);

    const fetchLatestNews = (leagueIdParam) => {
        let page = '&page=1';
        let limit = '?limit=10';
        let params = limit + '&league_id=' + leagueIdParam;


        get(FOOTBALL.LatestNewsEndPoint + params).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let newsTime = moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow();
                newsTime = newsTime.replace('an', '1');
                newsTime = newsTime.replace('a ', '1 ');
                newsTime = newsTime.replace('hours', 'h');
                newsTime = newsTime.replace('hour', 'h');
                newsTime = newsTime.replace('minutes', 'm');
                newsTime = newsTime.replace('minute', 'm');
                newsTime = newsTime.replace('days', 'd');
                newsTime = newsTime.replace('day', 'd');
                let news = {
                    is_snack: data.is_snack,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: newsTime,
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            let tmp = chunk(tempNewsData, 18);
            // console.log('tmp', tmp);
            setLatestNews(tmp);
            setLatestNewsLoading(false);
        }).catch((err) => {
            setLatestNewsLoading(false);
            console.error(err);
        });
    };

    const fetchTransferNews = () => {
        let page = '&page=1';
        let limit = '?limit=17';
        get(FOOTBALL.TransferNewsEndPoint).then((response) => {
            let tempNewsData = [];
            // eslint-disable-next-line array-callback-return
            response.data.map((data) => {
                let news = {
                    is_snack: data.is_snack,
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London').local().startOf('seconds').fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                };
                tempNewsData.push(news);
            });
            setTransferNews(tempNewsData);
            // setLoading(false);
            setTransferNewsLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {
        if (store.misc.appInit) {
            let tmp = store.misc.appInit || {};
            tmp.clubName = tmp.team_name || TEAM_NAME[target];
            tmp.clubId = tmp.team_id;
            setClubDetails(tmp);
        }
    }, [store.misc.appInit]);

    useEffect(() => {
        searchParams.get('league_id') && setLeagueId(parseInt(searchParams.get('league_id') || '0'));
        searchParams.get('teamType') && setTeamType(searchParams.get('teamType') || 'team');
    }, [location.pathname, searchParams])

    useEffect(() => {
        if (!(leagueId > 0) && clubDetails) {
            !(leagueId > 0) && clubDetails?.league_gs_id && fetchLatestNews(clubDetails.league_gs_id);
            !(leagueId > 0) && transferNews === null && fetchTransferNews();
        }
    }, [clubDetails]);


    const renderClubPageTeamType = () => {
        return (
            <section className={`${isMobile ? 'mt-1' : 'mt-5'}`}>
                {/*Section 1 */}
                <LatestNews isClubPage={true} isMobile={isMobile} data={clubDetails} limitRight={2} target={target}
                            showMoreStories={false}/>
                {/*Section 2 */}
                {latestNews?.map((value, index) => (
                    <NewsOnSlider id={index} isMobile={isMobile}
                                  bgColor={clubDetails.league_bg_color || COLORS[target]}
                                  fontColor={clubDetails.league_font_color || FONT_COLOR[target]}
                                  matchData={value} loading={latestNewsLoading}
                                  leagueName={clubDetails.league_name} isClubPage={true}
                    />
                ))}
                {/*Section 3 */}
                {/*{!transferNewsLoading &&*/}
                {/*    <SingleNewsSection news={transferNews} isMobile={isMobile} title={'Transfer News'}/>*/}
                {/*}*/}
                {/*Section 4 */}

                {/*Section 5 */}
                <MoreStories data={clubDetails} setPage={setPage} page={2} target={target} reverse={false} isNewsPage={true}/>
            </section>
        );
    };

    const renderClubPageLeague = () => {
        return (
            <section className={`${isMobile ? '' : 'mt-5'}`}>
                {/*Section 1 */}
                <MoreStories page={page} setPage={setPage} target={target} pageTitle={'Latest News'} limitRight={20}
                             isMobile={isMobile}/>
            </section>
        );
    };

    const renderMobileMenu = () => {
        return (
            <div className={'d-flex ms-5 me-5 shadow-nav-dark justify-content-around align-items-center'}
                 style={{
                     marginTop: `60px`,
                     borderRadius: '21px',
                     height: '43px',
                     backgroundColor: '#e4e4e4'
                 }}
            >
                {
                    store.football.navbarTeamTypes && store.football.navbarTeamTypes.map((item) => {
                        if (item.display_on.includes('news')) {
                            return (
                                <Link to={`/news?teamType=${item.teamType} `}
                                      className={'text-decoration-none'}
                                >
                                    <div className={`${TEXT_COLOR[target]} bebasFont font-18 ps-3 pe-3`}
                                         style={{
                                             fontWeight: queryParams.search === `?teamType=${item.teamType}` ? '800' : '500',
                                             backgroundColor: queryParams.search === `?teamType=${item.teamType}` ? '#fff' : '#e4e4e4',
                                             borderRadius: '21px',
                                             boxShadow: queryParams.search === `?teamType=${item.teamType}` && '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
                                         }}
                                    >
                                        {item.name}
                                    </div>
                                </Link>
                            )
                        }
                    })
                }
            </div>
        );

        // old design
        // return (
        //     <div className={'slider-mobile-news-container shadow-nav-dark'} style={{marginTop: `80px`}}>
        //         {
        //             store.football.navbarTeamTypes && store.football.navbarTeamTypes.map((item) => {
        //                 if (item.display_on.includes('news')) {
        //                     return (
        //                         <MobileNewsMenuItem title={item.name} uri={`/news?teamType=${item.teamType}`} selected={queryParams.search === `?teamType=${item.teamType}`}/>
        //                     )
        //                 }
        //             })
        //         }
        //         {store.football.navbarLeagues && store.football.navbarLeagues.map((item) => (
        //             <MobileNewsMenuItem title={item.name} uri={`/news?league_id=${item.league_id}`} selected={queryParams.search === `?league_id=${item.league_id}`}/>
        //         ))}
        //     </div>
        // );
    }

    const renderHomePageMain = () => {
        return (
            <>
                {isMobile ? renderMobileMenu() : null}
                {leagueId > 0 ? renderClubPageLeague() : renderClubPageTeamType()}
            </>
        )
    };

    const renderSnackNewsList = () => {
        return (
            <>
                <LatestNews newsEndpoint={'/api-almet/v2.0/Football/news?snack=1'}/>
            </>
        );
    }

    return searchParams.get('snack') ? renderSnackNewsList() : renderHomePageMain();

};

export default NewsAndSocials;
