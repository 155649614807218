import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import moment from "moment";
import {getHostname} from "../../CustomFunctions/getHostname";
import {FANZINE, NewsEndPoint} from "../../Axios/EndPoints";
import {get} from "../../Axios/get";
import {getCountryForTimezone} from "countries-and-timezones";
import {DOMAIN_EXTENSION, MATCH_STATES, SCREEN_SIZE} from "../../Configs/Constants";
import axios from "axios";
import {FONT_COLOR, NAV_CLASS} from "../../Configs/Targets";
import {Button} from "react-bootstrap";
import FanzineClubCalendar from "../Football/FanzineClubCalendar";
import {GenderNavbar} from "../../Components/Navbar/GenderNavbar";
import UpComingMatch from "../../Components/Layout/Football/UpComingMatch";
import Matches from "../../Components/Layout/Football/Matches";
import RightNewsAds from "../../Components/Layout/News/RightNewsAds";
import {SiteMetaHelmet} from "../../Components/SiteMetaHelmet";
import {Loader} from "react-bootstrap-typeahead";
import {SubNavbar} from "../../Components/Navbar/SubNavbar";
import GoogleAd from "../../Components/Ads/GoogleAd";
import Skeleton from "react-loading-skeleton";

const HomeFixtures = (props) => {
    const {} = props;

    let params = useParams();
    let store = useSelector((state) => state);
    // console.log("store in all football =", store);
    let queryParams = useLocation();
    const [searchParams,] = useSearchParams();
    const navigate = useNavigate();

    let [apiPage, setApiPage] = useState(1);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    let [matchData, setMatchData] = useState(null);
    let [matchDataLoading, setMatchDataLoading] = useState(true);
    let [latestNews, setLatestNews] = useState([]);
    let [footballLeagues, setFootballLeagues] = useState(null);
    let [selectedLeague, setSelectedLeague] = useState(0);
    let [selectedDate, setSelectedDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
    let [selectedDatePicker, setSelectedDatePicker] = useState();
    let [liveMatchData, setLiveMatchData] = useState(null);
    let [isFanzineClubMatches, setIsFanzineClubMatches] = useState(true);
    let [leagueMatchesGroupedByDate, setLeagueMatchesGroupedByDate] = useState(null);
    let [selectedLeagueDate, setSelectedLeagueData] = useState(null);
    let [isLeagueSelected, setIsLeagueSelected] = useState(false);

    const [teamType, setTeamType] = useState('team');
    const [teamData, setTeamData] = useState(null);
    let {team} = useParams();
    const location = useLocation();

    const [selectedTeamType, setSelectedTeamType] = useState('team')

    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;


    const handleScroll = (e) => {
        const pageBottom =
            document.body.scrollHeight - document.documentElement.clientHeight -
            10 <=
            document.documentElement.scrollTop;
        if (pageBottom && document.body.scrollHeight > 1000) {
            setApiPage((prevState) => {
                return prevState + 1;
            });
        }
    };

    const fetchNews = (isNew) => {
        let searchParams = queryParams.search;

        if (!searchParams) {
            searchParams = '?main_site=1&snack=1';
        }

        let page = `&page=${apiPage}`;
        let limit = `&limit=48`;

        get(NewsEndPoint + searchParams + limit + page).then((responseNews) => {
                let tempNewsData = [];
                responseNews.data.map((data) => {
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: moment.tz(data.datetime, 'Europe/London')
                            .local()
                            .startOf('seconds')
                            .fromNow(),
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data.is_snack,
                        main_team: data.main_team,
                        type: 'news',
                    };
                    tempNewsData.push(news);
                });
                if (isNew) {
                    setLatestNews(tempNewsData);
                } else {
                    setLatestNews([...latestNews, ...tempNewsData]);
                }
            },
        ).catch((error) => console.log(error));
        // setLoading(false);

    };

    const fetchFixtures = () => {

        let tempTeamType = selectedTeamType;
        let url = ''
        let timezone = ''
        let hostName = '';

        if (selectedTeamType === 'all') {
            let params = queryParams.search;
            if (params === '?team_type=all') {
                params = '';
            }
            if (!params) {
                params = `?date=${'2023-10-21' || selectedDate ||
                moment(Date.now()).format('YYYY-MM-DD')}`;
            }
            timezone = getCountryForTimezone(
                Intl.DateTimeFormat().resolvedOptions().timeZone);
            params = params + ('&team=' + teamType) + '&timezone=utc';

            hostName = teamData?.clubUrl?.split('.')[1];

            if (target === 'fanzine') {
                url = 'https://api2.fanzine.com/api-almet/v2.0/Football/matches' +
                    params;
            } else {
                url = `https://api2.${target}${DOMAIN_EXTENSION[target] ? DOMAIN_EXTENSION[target] : '.com'}/api-almet/v2.0/Football/matches` +
                    params;
            }
            console.log('homeFixturesTeamType', selectedTeamType, params, url);
        } else {
            url = `https://api2.${target}${DOMAIN_EXTENSION[target] ? DOMAIN_EXTENSION[target] : '.com'}/api-almet/v2.0/Football/matchesByTeam?team_type=${tempTeamType}`
        }


        axios.get(url).then((response) => {
            // eslint-disable-next-line array-callback-return
            let tempData = [];

            if (selectedTeamType === 'all') {
                tempData = response.data;
            } else {
                tempData = response.data.future
                tempData.push(...response.data.past)
            }

            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i].new_web_order == 0) {
                    tempData[i].new_web_order = '100';
                }
                if (tempData[i].league_id == 0) {
                    tempData[i].new_web_order = '1000';
                }
                for (let j = 0; j < tempData[i].matches.length; j++) {
                    if (MATCH_STATES.IN_PLAY.includes(tempData[i].matches[j].current_time_match)) {
                        // if (tempData[i].matches[j].current_time_match !== 'FT') {
                        tempData[i].matches[j].current_time_match += '\'';
                    }
                    if (tempData[i].matches[j].current_time_match === '') {
                        tempData[i].matches[j].current_time_match = null;
                    }
                    // tempData[i].matches[j].current_time_match = !['', 'FT'].includes(tempData[i].matches[j].current_time_match) ? tempData[i].matches[j].current_time_match + "'" : null;
                }
            }
            tempData?.sort(function (a, b) {
                return a?.sort_v2 - b?.sort_v2;
            });
            // tempData.pop();
            // console.log('sorted tempdata=', tempData);
            setMatchData(tempData);
            setMatchDataLoading(false);
        }).catch((err) => {
            console.error(err);
        });
        setLoading(false);

    };

    const selectedDay = (e) => {
        let tmpDate = moment(e).format('YYYY-MM-DD');
        setSelectedDate(tmpDate);
        setSelectedDatePicker(e);
        // console.log('tmpdate =', tmpDate);
    };

    const fetchLiveMatches = () => {
        let params = queryParams.search;
        if (!params) {
            params = `?date=${selectedDate ||
            moment(Date.now()).format('YYYY-MM-DD')}`;
        }

        let url = 'https://api2.fanzine.com/api-almet/v2.0/Football/matches/live' +
            params;
        axios.get(url).then((response) => {
            setLiveMatchData(response?.data);
            setMatchDataLoading(false);
        }).catch((err) => {
            console.error(err);
        });
    };

    const fetchLeaguesMatchesByDate = () => {
        let params = searchParams.get('league_id');

        if (params) {
            let timezone = getCountryForTimezone(
                Intl.DateTimeFormat().resolvedOptions().timeZone);
            params = params + '/matchesByDate?limit=1000&timezone=utc';
            // + timezone.timezones[0]

            get(FANZINE.MatchesByDateEndPoint + params).then((response) => {
                setLeagueMatchesGroupedByDate(response.data.matches);
                setIsLeagueSelected(true);
                setLoading(false);

            }).catch((err) => {
                console.error(err);
                setLoading(false);

            });
        }

    };

    useEffect(() => {
        store.football?.navbarTeamSlugsId?.length &&
        store.football.navbarTeamSlugsId.map((value, index) => {
                if (team === value?.club) {
                    setTeamData(value);
                }
            },
        );

    }, [team, store.football?.navbarTeamSlugsId, teamData, location.pathname]);

    useEffect(() => {
        setSelectedDate(searchParams.get('date') || moment(new Date()).format('YYYY-MM-DD'));
        setLoading(true);
        window.addEventListener('scroll', handleScroll);
        setApiPage(1);
        fetchNews(true);
        searchParams.get('league_id') == null && fetchFixtures();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            setMatchData(null);
            setLoading(true);
            setMatchDataLoading(true);
        };
    }, [queryParams.search, selectedLeague, searchParams, teamType, teamData]);

    useEffect(() => {
        navigate(`/matches?team_type=${selectedTeamType}`);
    }, [selectedTeamType]);

    useEffect(() => {
        setFootballLeagues(store?.football?.fanzineLeagues);
    }, [store?.football?.fanzineLeagues]);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect(() => {
        selectedTeamType === 'all' && fetchLeaguesMatchesByDate();
        // setIsLeagueSelected(true);
    }, [searchParams.get('league_id')]);

    useEffect(() => {
    }, [isLeagueSelected]);

    //fetching Live Matches After 10s
    useEffect(() => {
        let isParamDate = !!searchParams.get('date');

        let checkDateWith = isParamDate ? searchParams.get('date') : selectedDate;

        if (checkDateWith == moment(Date.now()).format('YYYY-MM-DD')) {
            // console.log('today');
            const interval = setInterval(() => {
                selectedTeamType === 'all' && fetchLiveMatches();
            }, 30000);

            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {
    }, [isLeagueSelected]);


    const handleTeamTypeButtonClick = (teamType) => {
        setSelectedTeamType(teamType);
    }

    const renderTeamTypeButtons = (isClub) => {

        const club_BG = NAV_CLASS[target];
        const club_text = FONT_COLOR[target]

        return (
            <div className={'d-flex align-items-center justify-content-center'}>
                <Button
                    onClick={() => handleTeamTypeButtonClick('all')}
                    style={{
                        // color: selectedTeamType === 'all' ? '#000' : '#757575',
                        fontWeight: selectedTeamType === 'all' ? 900 : 500,
                        width: '153px',
                        height: '41px',
                        margin: '27px 40px 30px 53.8px',
                        // backgroundColor: selectedTeamType === 'all' ? '#74dbef' : '#fff',
                        border: `solid 1px #e4e4e4`,
                    }}
                    className={`${(selectedTeamType === 'all' && isClub) ? `${club_text} ${club_BG} ` : selectedTeamType === 'all' ? 'bg-fanzine-dark font-dark' : 'text-gray-custom-1 bg-white'}`}
                >
                    All
                </Button>
                <Button
                    onClick={() => handleTeamTypeButtonClick('team')}
                    style={{
                        // color: selectedTeamType === 'team' ? '#000' : '#757575',
                        fontWeight: selectedTeamType === 'team' ? 900 : 500,
                        width: '153px',
                        height: '41px',
                        margin: '27px 40px 30px',
                        // backgroundColor: selectedTeamType === 'team' ? '#74dbef' : '#fff',
                        border: `solid 1px #e4e4e4`,
                    }}
                    className={`${(selectedTeamType === 'team' && isClub) ? `${club_text} ${club_BG} ` : selectedTeamType === 'team' ? 'bg-fanzine-dark font-dark' : 'text-gray-custom-1 bg-white'}`}
                >
                    Men
                </Button>
                <Button
                    onClick={() => handleTeamTypeButtonClick('women')}
                    style={{
                        // color: selectedTeamType === 'women' ? '#000' : '#757575',
                        fontWeight: selectedTeamType === 'women' ? 900 : 500,
                        width: '153px',
                        height: '41px',
                        margin: '27px 119px 30px 40px',
                        // backgroundColor: selectedTeamType === 'women' ? '#74dbef' : '#fff',
                        border: `solid 1px #e4e4e4`,
                    }}
                    className={`${(selectedTeamType === 'women' && isClub) ? `${club_text} ${club_BG} ` : selectedTeamType === 'women' ? 'bg-fanzine-dark font-dark' : 'text-gray-custom-1 bg-white'}`}
                >
                    Women
                </Button>
            </div>
        );
    };

    const renderNews = (news, sport, isMobile, isClub) => {

        return (
            <div className="bg-background overflow-hidden">
                <div className={`${!isMobile ? 'container' : 'w-100'}`}>
                    <div
                        className={`${!isMobile ? 'row' : 'mb-5'}`}
                        style={{marginLeft: '0%', marginRight: '0%'}}
                    >
                        <div
                            className={`col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 ${isMobile ?
                                '' :
                                ' border-radius-15px'} h-100`}>
                            {!isMobile && <div>
                                {renderTeamTypeButtons(isClub)}
                            </div>}

                            <div className={`${isMobile ? '' : 'mb-5'}`}>
                                <div className={'customContainer'}>
                                    <div className={`row ${isMobile && 'me-auto ms-auto'}`}>
                                        {selectedTeamType === 'all' && leagueMatchesGroupedByDate == null &&
                                            <FanzineClubCalendar
                                                isClub={true}
                                                isFanzineClubMatches={true}
                                                selectedDay={selectedDay}
                                                setSelectedDate={setSelectedDate}
                                                selectedDate={selectedDate}
                                                isMobile={isMobile}
                                                sport={'football'}
                                                team={team}
                                            />
                                        }
                                        <GenderNavbar
                                            isFanzineClubMatches={true}
                                            setSelectedTeamType={setSelectedTeamType}
                                            selectedTeamType={selectedTeamType}
                                            setParams={setTeamType}
                                            isMobile={isMobile}/>
                                        {isMobile && target && <UpComingMatch/>}
                                        <Matches
                                            isFanzineClubMatches={true}
                                            selectedTeamType={selectedTeamType}
                                            isMobile={isMobile}
                                            setSelectedLeagueData={setSelectedLeagueData}
                                            selectedLeagueDate={selectedLeagueDate}
                                            isLeagueSelected={isLeagueSelected}
                                            setIsLeagueSelected={setIsLeagueSelected}
                                            setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}
                                            leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                            selectedDate={selectedDate}
                                            liveMatchData={liveMatchData}
                                            matchData={matchData}
                                            store={store}
                                            matchStates={MATCH_STATES}
                                        />
                                        {
                                            (!matchData) &&
                                            <div className={'text-center'}>
                                                <Skeleton height={50} count={10}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RightNewsAds isMobile={isMobile} limit={25}/>
                    </div>
                </div>
            </div>
        );
    };

    const renderFanzineClub = () => {
        return (
            <>
                <SiteMetaHelmet sport={'Football'} urlEndpoint={'football'}/>
                <Loader loading={loading}/>
                <SubNavbar data={teamData} isMobile={isMobile} isClub={team}/>
                <div className="d-flex justify-content-center align-items-center h1">
                    {!isMobile && <GoogleAd id={'3349434796'}/>}
                </div>
                {renderNews(latestNews, 'Football', isMobile)}
            </>
        );
    };

    const renderClub = () => {
        return (
            <>
                <SiteMetaHelmet sport={'Football'} urlEndpoint={'football'}/>
                {loading && <Loader/>}
                {/*<SubNavbar data={teamData} isMobile={isMobile} isClub={team}/>*/}
                <div className="d-flex justify-content-center align-items-center h1 mt-5">
                    {!isMobile && <GoogleAd
                        id={'3349434796'}/>}
                </div>
                {renderNews(latestNews, 'Football', isMobile, true)}
            </>
        );
    };

    return target === 'fanzine' ? renderFanzineClub() : renderClub();
};

export default HomeFixtures;
