import LatestNews from './LatestNews';
import LatestVideos from './LatestVideos'

const LatestSection = (props) => {

	const {isMobile, data, target, showMoreStories} = props;
	const renderLatestVideos = () => {
		return <LatestVideos showOnSlider={true} data={data} isMobile={isMobile}/>
	}

	const renderLatestNews = () => {
		return <LatestNews data={data} isMobile={isMobile} target={target} showMoreStories={showMoreStories}/>
	}

	const renderLatestSectionMain = () => {
		return (
			<div>
				{renderLatestNews()}
				{renderLatestVideos()}
			</div>
		)
	}

	return renderLatestSectionMain()

}

export default LatestSection;
