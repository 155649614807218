import {SubNavbar} from '../../Components/Navbar/SubNavbar';
import LatestVideosAndUpNext from './SliderVideos/LatestVideosUpNext';
import SliderNoBackground from './SliderVideos/sliderNoBackground';
import GoogleAd from '../../Components/Ads/GoogleAd';
import React, {useEffect, useState} from 'react';
import {SCREEN_SIZE} from '../../Configs/Constants';
import SpecificLeagueVideosOnly from './SliderVideos/SpecificLeagueVideosOnly';
import {useLocation, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getHostname} from '../../CustomFunctions/getHostname';
import MoreStories from './SliderVideos/MoreStories';
import axios from 'axios';
import {FOOTBALL, WebLinks} from '../../Axios/EndPoints';

const VideosMain = () => {
    let {team} = useParams();

    const store = useSelector((state) => state);
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    const location = useLocation();

    const [isMobile, setIsMobile] = useState(false);
    const [teamData, setTeamData] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [fetchedFanzineClubData, setFetchedFanzineClubData] = useState(null);
    const [domainClubData, setDomainCLubData] = useState(null)


    const fetchFanzineClubDetails = () => {
        let baseURL = '';
        baseURL = teamData?.clubUrl?.replace('www', 'api2');
        axios.get(baseURL + WebLinks).then((response) => {
            if (response.data.success) {
                setFetchedFanzineClubData(response.data.data);
            }
        }).catch((err) => {
            console.error(err);
        });
    };


    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    // useEffect(() => {
    //
    //
    //   store.football?.navbarTeamSlugsId?.length && store.football.navbarTeamSlugsId.map((value, index) => {
    //         if (target === value?.club) {
    //           setTeamData(value);
    //         }
    //       },
    //   );
    // }, [target, store.football?.navbarTeamSlugsId, teamData, location.pathname]);

    useEffect(() => {

        store.football?.navbarTeamSlugsId?.length &&
        store.football.navbarTeamSlugsId.map((value, index) => {
                if (team === value?.club) {
                    setTeamData(value);
                }
            },
        );


        setDomainCLubData(store?.misc?.appInit)

    }, [team, store, teamData, location.pathname]);

    useEffect(() => {
        if (team && teamData) {
            fetchedFanzineClubData === null && fetchFanzineClubDetails();
        }
    }, [teamData])


    const renderFanzineOtherSportsVideos = () => {
        return (
            <div className={'bg-background'}>
                <SubNavbar isMobile={isMobile}/>
                <LatestVideosAndUpNext
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    isMobile={isMobile}/>
                <MoreStories
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    isMobile={isMobile}/>
            </div>
        );
    };

    const renderFanzineFootballVideos = () => {
        return typeof team !== 'undefined' ?
            renderFanzineClubVideos()
            :
            (
                <div className={'bg-background'}>
                    <SubNavbar isMobile={isMobile}/>
                    <LatestVideosAndUpNext setSelectedVideo={setSelectedVideo}
                                           selectedVideo={selectedVideo}
                                           isMobile={isMobile}/>
                    {/* Match Highlights */}
                    {!isMobile && <SliderNoBackground
                        setSelectedVideo={setSelectedVideo}
                        endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint + '&type=match'}
                        leagueName={'MATCH HIGHLIGHTS'}
                        leagueId={domainClubData?.league_gs_id || '1204'}
                    />
                    } {!isMobile && <GoogleAd id={'4009154580'}/>}
                    {/* Match Highlights */}
                    {!isMobile && <SliderNoBackground
                        setSelectedVideo={setSelectedVideo}
                        isGoal={true}
                        endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint + '&type=goal'}
                        leagueName={'GOAL HIGHLIGHTS'}
                        leagueId={domainClubData?.league_gs_id || '1205'}
                    />
                    }
                    {!isMobile &&
                        <SpecificLeagueVideosOnly
                            setSelectedVideo={setSelectedVideo}
                            isMobile={isMobile}/>}
                </div>
            );
    };

    const renderFanzineVideos = () => {
        return location.pathname.includes('football') ?
            renderFanzineFootballVideos()
            :
            renderFanzineOtherSportsVideos();
    };

    const renderClubVideos = () => {
        return (
            <div className={'bg-background'}>
                <LatestVideosAndUpNext
                    isClub={true}
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    teamData={teamData}
                    isMobile={isMobile}/>
                {/* Match Highlights */}
                {domainClubData && !isMobile && <SliderNoBackground
                    isClub={true}
                    endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint +
                        '&type=match'}
                    leagueName={'MATCH HIGHLIGHTS'}
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    isMobile={isMobile}
                    leagueId={domainClubData?.league_gs_id}
                />}
                {!isMobile && <GoogleAd id={'4009154580'}/>}
                {/* Match Highlights */}
                {domainClubData && !isMobile && <SliderNoBackground
                    endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint + '&type=goal'}
                    leagueName={'GOAL HIGHLIGHTS'}
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    isMobile={isMobile}
                    leagueId={domainClubData?.league_gs_id}
                />}
                {
                    !isMobile && <SpecificLeagueVideosOnly isClub={true} teamData={teamData}
                                                           isMobile={isMobile}/>
                }
            </div>
        );
    };

    const renderFanzineClubVideos = () => {

        return (
            <div className={'bg-background'}>
                <SubNavbar data={teamData} isMobile={isMobile} isClub={team}/>
                <LatestVideosAndUpNext
                    isClub={true}
                    isFanzineClub={true}
                    setSelectedVideo={setSelectedVideo}
                    selectedVideo={selectedVideo}
                    fanzineClubData={teamData}
                    isMobile={isMobile}/>
                {/* Match Highlights */}
                {!isMobile && <SliderNoBackground
                    isClub={true}
                    setSelectedVideo={setSelectedVideo}
                    endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint + '&type=match'}
                    leagueName={'MATCH HIGHLIGHTS'}
                    leagueId={fetchedFanzineClubData?.league_gs_id || '1204'}/>
                } {!isMobile && <GoogleAd id={'4009154580'}/>}
                {/* Match Highlights */}
                {!isMobile && <SliderNoBackground
                    setSelectedVideo={setSelectedVideo}
                    endpoint={FOOTBALL.HighlightsFanzineVideosEndPoint + '&type=goal'}
                    leagueName={'GOAL HIGHLIGHTS'}
                    leagueId={fetchedFanzineClubData?.league_gs_id || '1205'}/>
                }
                {!isMobile &&
                    <SpecificLeagueVideosOnly
                        setSelectedVideo={setSelectedVideo}
                        selectedVideo={selectedVideo}
                        isFanzineClub={true}
                        isClub={true} teamData={teamData}
                        fetchedFanzineClubData={fetchedFanzineClubData}
                        isMobile={isMobile}/>}
            </div>
        );
    };

    const renderMain = () => {
        return (target === 'fanzine') ?
            renderFanzineVideos() :
            renderClubVideos();

    };

    return renderMain();

};

export default VideosMain;
