export const getHostname = (url) => {
  let parsed_url = {};

  if (url == null || url.length === 0) return parsed_url;

  let protocol_i = url.indexOf("://") === -1 ? 0 : url.indexOf("://");
  parsed_url.protocol = url.substr(0, protocol_i) || "http";
  // debugger
  let remaining_url = url.substr(protocol_i, url.length);
  remaining_url = remaining_url.replace("www.", "");
  let domain_i = remaining_url.indexOf("/");
  domain_i = domain_i === -1 ? remaining_url.length : domain_i;
  parsed_url.domain = remaining_url.substr(0, domain_i);
  parsed_url.path =
    domain_i === -1 || domain_i + 1 === remaining_url.length
      ? null
      : remaining_url.substr(domain_i + 1, remaining_url.length);

  let domain_parts = parsed_url.domain.split(".");
  switch (domain_parts.length) {
    case 1:
      parsed_url.subdomain = null;
      parsed_url.host = domain_parts[0];
      parsed_url.tld = domain_parts[1];
      break;
    case 2:
      parsed_url.subdomain = null;
      parsed_url.host = domain_parts[0];
      parsed_url.tld = domain_parts[1];
      break;
    case 3:
      parsed_url.subdomain = domain_parts[0];
      parsed_url.host = domain_parts[1];
      parsed_url.tld = domain_parts[2];
      break;
    case 4:
      parsed_url.subdomain = domain_parts[0];
      parsed_url.host = domain_parts[1];
      parsed_url.tld = domain_parts[2] + "." + domain_parts[3];
      break;
    default:
      console.error('Unable to getHostName')
      break;
  }

  parsed_url.parent_domain = parsed_url.host + "." + parsed_url.tld;
  // console.table(parsed_url);
  if (parsed_url.host === "localhost" || parsed_url.host === "168") {
    parsed_url.host = 'fanzine';//process.env.REACT_APP_HOSTNAME;
  }


  return parsed_url;
};

