import React, {useEffect, useState} from 'react';
import {withRouter} from '../../CustomFunctions/withRouter';
import {get} from '../../Axios/get';
import {
    CRICKET,
    FOOTBALL,
    MLB,
    NBA, NCAA,
    NewsEndPoint,
    NFL,
} from '../../Axios/EndPoints';
import {Container} from 'react-bootstrap';
import FanzineNewsCard from '../../Components/Cards/FanzineNewsCard';
import moment from 'moment-timezone';
import {LOADING_GIF, TEXT_COLOR} from '../../Configs/Targets';
// import News from "../Components/Homepage/News";
// import {chunk} from "../Components/Helper/chunk";
import {getHostname} from '../../CustomFunctions/getHostname';
import {BsLink45Deg} from 'react-icons/bs';
import {SCREEN_SIZE} from '../../Configs/Constants';
import Billboard from '../../Components/Ads/Billboard';
import DMPU from '../../Components/Ads/DMPU';
import Loader from '../../Components/Loader/Loader';
import RecommendationUnit from '../../Components/Ads/RecommendationUnit';
import VideoAd from '../../Components/Ads/VideoAd';
import Leaderboard from '../../Components/Ads/Leaderboard';
import MPU from '../../Components/Ads/MPU';
import SkySportsAd from "../../Components/Ads/SkySportsAd";
import GoogleAd from "../../Components/Ads/GoogleAd";
import NewsSectionHeading from "../../Components/NewsSectionHeading";
import {useLocation} from 'react-router-dom';
import Footer from '../../Components/Footer';
import FanzineSportsNavBar from '../../Components/Navbar/FanzineSportsNavBar';

const SingleNewsPage = (props) => {
    let newsId = props.match.params.id;
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    const target = domainObject.host;
    let textColor = TEXT_COLOR[target];
    let loadingGif = LOADING_GIF[target];

    let [iframeHeight, setIframeHeight] = useState(0);
    let [iframeData, setIframeData] = useState('null');
    let [latestNews, setLatestNews] = useState([]);
    let [relatedStories, setRelatedStories] = useState([]);
    let [news, setNews] = useState(null);
    let [newsContent, setNewsContent] = useState([]);
    let [loading, setLoading] = useState(true);
    let [isMobile, setIsMobile] = useState(false);
    let [isTwitterScriptLoaded,setIsTwitterScriptLoaded] = useState(false)
    const location = useLocation()


    const addDefaultSrc = (ev) => {
        ev.target.src = require(
            '../../assets/images/football-shots-you-need-to-know.jpg');
    };

    const fetchSingleNews = () => {

        let URL = '';

        switch (location.pathname.split('/')[1]) {
            case 'football':
                URL += NewsEndPoint;
                break;
            case 'cricket':
                URL += CRICKET.NewsEndPoint;
                break;
            case 'nfl':
                URL += NFL.NewsEndPoint;
                break;
            case 'nba':
                URL += NBA.NewsEndPoint;
                break;
            case 'ncaa':
                URL += NCAA.NewsEndPoint;
                break;
            case 'mlb':
                URL += MLB.NewsEndPoint;
                break;
            default:
                URL += NewsEndPoint;
                break;

        }


        get(URL + `/slug/${newsId}`)
            .then((response) => {
                setNews(response.data);
                if (false || !response.data.is_snack) {
                    // setNewsContent()
                    // window.open(response.data.url, '_self');
                } else {
                    /*if (target !== 'fanzine'){
                        window.open(`https://fanzine.com/football/${response.data.main_team}/${response.data.slug}`, '_self');
                    }*/
                    let val = response.data.text;
                    let parser = new DOMParser();
                    let valAsHtml = parser.parseFromString(val, 'text/html');

                    let TwitterLink = valAsHtml.getElementsByClassName('twitter-tweet');
                    let twitterArray = []

                    for (let i = 0; i < TwitterLink.length; i++) {
                        console.log('twitter =',valAsHtml.getElementsByClassName('twitter-tweet')[i])

                        let twitt =valAsHtml.getElementsByClassName('twitter-tweet')[i];
                        for(let j=0;j<twitt.length;j++)
                        {
                            console.log('inside twitter =', valAsHtml[j])

                        }


                        // valAsHtml.getElementsByClassName('twitter-tweet')[i].remove();
                    }

                    // for (let i = 0; i < TwitterLink.length; i++) {
                    //     valAsHtml.getElementsByClassName(
                    //         'twitter-tweet')[i].remove();
                    // }

                    let aTags = valAsHtml.getElementsByTagName('a');
                    for (let i = 0; i < aTags.length; i++) {
                        valAsHtml.getElementsByTagName('a')[i].setAttribute('target','_blank');
                    }
                    let h2Tags = valAsHtml.getElementsByTagName('h2');
                    for (let i = 0; i < h2Tags.length; i++) {
                        valAsHtml.getElementsByTagName('h2')[i].classList.add(
                            'robotoCondensedBold');
                    }
                    let pTags = valAsHtml.getElementsByTagName('p');
                    for (let i = 0; i < pTags.length; i++) {
                        valAsHtml.getElementsByTagName('p')[i].classList.add(
                            'robotoCondensedBold');
                    }
                    let imageTag = valAsHtml.getElementsByTagName('img');
                    for (let i = 0; i < imageTag.length; i++) {
                        valAsHtml.getElementsByTagName('img')[i].classList.add(
                            'img-fluid');
                    }
                    let iframeTag = valAsHtml.getElementsByTagName('iframe');
                    for (let i = 0; i < iframeTag.length; i++) {
                        valAsHtml.getElementsByTagName('iframe')[i].classList.add(
                            'w-fill');
                    }
                    let gmsVideoContainers = valAsHtml.getElementsByClassName(
                        'gms-video-container');
                    for (let i = 0; i < gmsVideoContainers.length; i++) {
                        valAsHtml.getElementsByClassName(
                            'gms-video-container')[i].remove();
                    }
                    let cInblogAd = valAsHtml.getElementsByClassName('c-inblog_ad');
                    for (let i = 0; i < cInblogAd.length; i++) {
                        valAsHtml.getElementsByClassName('c-inblog_ad')[i].remove();
                    }
                    let inblogAdWide = valAsHtml.getElementsByClassName(
                        'inblog_ad_wide');
                    for (let i = 0; i < inblogAdWide.length; i++) {
                        valAsHtml.getElementsByClassName('inblog_ad_wide')[i].remove();
                    }
                    val = valAsHtml.getElementsByTagName('body')[0].innerHTML;
                    window.newsContent = valAsHtml;
                    val = val.replaceAll('</p>', '</p>###');
                    let split = val.split('###');
                    let newArr = split.filter((a) => a);

                    setNewsContent(newArr);

                    if (target === 'fanzine') {
                        setLoading(false);
                    }
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const fetchLatestNews = () => {
        get(NewsEndPoint + `?main_site=1&limit=50`)
            .then((response) => {
                let prepareLatestNews = response.data.slice(5, 25);
                let prepareRelatedStories = response.data.slice(34, 50);
                let tempLatestNews = [], tempRelatedStories = [];
                prepareLatestNews.map((data) => {
                    let timeDifference = moment.utc(data.datetime)
                        .local()
                        .startOf('seconds')
                        .fromNow();
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: timeDifference,
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data?.is_snack,
                        is_mad: data?.is_mad,
                        main_team: data.main_team,
                    };
                    return tempLatestNews.push(news);
                });
                prepareRelatedStories.map((data) => {
                    let timeDifference = moment.utc(data.datetime)
                        .local()
                        .startOf('seconds')
                        .fromNow();
                    let news = {
                        news_id: data.news_id,
                        slug: data.slug,
                        news_image: data.image,
                        news_title: data.title,
                        news_url: data.url,
                        news_time: timeDifference,
                        news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                        news_publisher: {
                            publisher_name: data.publisher.name,
                            publisher_icon: data.publisher.icon,
                        },
                        is_snack: data?.is_snack,
                        is_mad: data?.is_mad,
                        main_team: data.main_team,
                    };
                    return tempRelatedStories.push(news);
                });
                setLatestNews(tempLatestNews);
                setRelatedStories(tempRelatedStories);
            });
    };

    useEffect(() => {
        setLoading(true);
        fetchSingleNews();
        if (target === 'fanzine') {
            fetchLatestNews();
        }
    }, [newsId]);

    useEffect(() => {
        if (window.screen.width <= SCREEN_SIZE.MOBILE) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [window.screen.width, isMobile]);

    useEffect((props)=> {
        let desiredSource = 'https://platform.twitter.com/widgets.js';
        let scripts = document.getElementsByTagName('script');
        let alreadyLoaded = false;

        for(let scriptIndex in document.scripts) {
            if(!alreadyLoaded && desiredSource === scripts[scriptIndex].src) {
                alreadyLoaded = true;
                setIsTwitterScriptLoaded(alreadyLoaded);
            }
        }
        if(!alreadyLoaded){
            // Run your code in this block?
            setIsTwitterScriptLoaded(false);
        }

        // console.log('alreadyLoaded -',alreadyLoaded)

    },[])

    const renderNewsHead = () => {
        return (
            <>
                <div className={'h1 robotoCondensedBold mt-3'}
                     dangerouslySetInnerHTML={{__html: news.title}}/>
                <div className={'mb-2 col-12'}>
                    <img src={news.image} alt="Fanzine_Logo"
                         className={`w-100 mt-4 Fanzine_Logo`}/>
                </div>
                <div>
                    <img onError={addDefaultSrc} src={news.publisher.icon} alt="News"
                         height={30}/>
                    <span
                        className={'sfProDisplayFont ms-2 me-2'}>{news.publisher.name}</span>
                    {/*<span className={`${textColor} font-weight-thin`}>Published </span>*/}
                    <span className={`float-end`}>
                        <span>{moment(news.datetime).format('D MMM YYYY, h:mm A')}</span>

                        <span>
                            <a
                                href={(news?.is_snack || news?.is_mad) ?
                                    `https://fanzine.com/football/${news.main_team}/${news.slug}` :
                                    news.url}
                                className={`ms-3`}
                                target={'_self'}
                                rel="noreferrer">
                                <BsLink45Deg size="20"/>
                            </a>
                        </span>
                    </span>
                </div>

            </>
        );
    };

    const renderBillboardBanner = () => {
        return (
            <div className="d-flex justify-content-center">
                {isMobile ?
                    <Leaderboard id={'snack_mob_top'}/> :
                    <Billboard id={'snack_dex3'}/>}
            </div>
        );
    };

    function renderRecommendationUnits() {
        return (<RecommendationUnit/>);
    }

    function renderRelatedStories() {
        return (
            <div className="row mb-4">
                <NewsSectionHeading title={'More Stories'} />
                {relatedStories.length ?
                    (
                        relatedStories.map((value) => (
                            <div className={'col-lg-6 pt-2 col-md-6 col-sm-12 col-xs-12'}>
                                <FanzineNewsCard
                                    news={value}
                                />
                            </div>
                        ))
                    )
                    : null}
            </div>
        );
    }

    function renderRightContent() {
        return (
            <div
                className="mt-5 col-xxl-4 col-xl-4 col-lg-4 col-md-4 d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                {
                    latestNews.length ?
                        (
                            latestNews?.map((value, index) => (

                                (index % 3 === 0) ?
                                    (<div
                                            className={`${index === 0 &&
                                            'mt-4 pt-3'} col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                                        >
                                            <GoogleAd id={'4009154580'}/>
                                        </div>
                                    )
                                    :
                                    (<FanzineNewsCard newsId={index} isMobile={isMobile}
                                                      news={value}
                                                      isNewsPage={false}/>)
                            ))
                        ) : ''
                }
                {!isMobile && <div
                    className={'double-mpu d-flex justify-content-center flex-column align-items-center h1 text-center'}>
                    {/*<DMPU id={'snack_dex10'}/>*/}
                    <GoogleAd id={'4009154580'}/>

                </div>}

                {/*{!isMobile && <div*/}
                {/*    className={'sticky-mpu d-flex justify-content-center flex-column align-items-center h1 text-center sticky-top'}>*/}
                {/*    <DMPU id={'snack_dmpu'}/>*/}
                {/*</div>}*/}
            </div>
        );
    }

    const render = () => {
        return null;
        return (
            <>
                <Container className={'mt-5 w-100 a-header-b'}>
                    {renderBillboardBanner()}
                    <div className={'row'}>
                        <div className={'col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12'}>
                            {
                                news ?
                                    renderNewsHead()
                                    : null
                            }
                            {
                                iframeHeight > 0 ?
                                    <iframe
                                        style={{height: iframeHeight}}
                                        height={iframeHeight}
                                        // src={'https://www.derbytelegraph.co.uk/sport/football/football-news/derby-county-wayne-rooney-sent-6589094'}
                                        srcDoc={iframeData}
                                        width={'100%'}
                                        scrolling={'no'}
                                    /> : <></>
                            }

                            {renderRecommendationUnits()}
                            {renderRelatedStories()}
                        </div>
                        {renderRightContent()}
                    </div>
                </Container>
            </>
        );
    };

    const renderSnack = () => {

        return (
            <>
                <Container
                    className={`mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-5 mt-sm-1 mt-2 ${!isMobile ?
                        'd-flex flex-row justify-content-center' :
                        ''}`}>
                    <div className={'customContainer'}>
                        {renderBillboardBanner()}

                        <div className="row">
                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                <div className={`col-12`}>
                                    {renderNewsHead()}
                                    <Container className={'mt-3'}>
                                        {
                                            isTwitterScriptLoaded && newsContent.map((value, index) => {

                                                console.log(`value ${index} =`,  value);

                                                return (
                                                    <>
                                                        <div className={'font-16'}
                                                             dangerouslySetInnerHTML={{__html: value}}/>
                                                        {index ===  1 ?
                                                            (<div className={`single-mpu d-flex justify-content-center`}>
                                                                <SkySportsAd/>
                                                            </div>) : null}
                                                    </>
                                                );
                                            })
                                        }

                                        {/*<div className={'single-mpu d-flex justify-content-center flex-column align-items-center mt-4 mb-4 col-xxl-6 col-xl-6 col-lg-6 col-md-6' +
                                            ' col-sm-6 offset-xxl-3 offset-xl-3 offset-lg-3 offset-md-3 offset-sm-3'}>
                                            <MPU id={'snack_dex9'}/>
                                        </div>*/}

                                    </Container>

                                    {renderRecommendationUnits()}

                                    {renderRelatedStories()}
                                </div>
                            </div>
                            {renderRightContent()}
                        </div>
                    </div>
                </Container>
            </>
        );
    };

    return (
        <>
            {target === 'fanzinesports' && <FanzineSportsNavBar/>}
            <Loader loading={loading}/>
            {renderSnack()}
            {/*{
                target === 'fanzine'
                    ? (news && news.is_snack ? renderSnack() : render())
                    : <div
                        className={'iframe-content'}>
                        <iframe
                            height={'100%'}
                            width={'100%'}
                            onLoad={() => {
                                setLoading(false);
                            }
                            }
                            src={news &&
                                `https://fanzine.com/football/${news.main_team}/${news.slug}`}/>
                    </div>
            }*/}
            <Footer/>
        </>
    );
};

export default withRouter(SingleNewsPage);
