import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {Link, useLocation} from 'react-router-dom';
import {useCallback, useEffect} from 'react';

const MatchItemLeague = (props) => {
  const {
    value,  selectedLeagueDate,
    setLeagueMatchesGroupedByDate, isLeagueSelected,
    setIsLeagueSelected, isMobile, selectedTeamType,
  } = props;

  const addDefaultSrc = (ev) => {
    ev.target.src = require("../../../assets/images/default-league.png");
  };
  const location = useLocation();

  const handleClearParams = useCallback(() => {
    setIsLeagueSelected(false);
    console.log('im in handle clear params');
    setLeagueMatchesGroupedByDate(null);
  }, []);

  useEffect(() => {
  }, []);

  const renderCricketMobile = () => {
    // matches?.series?.name
    return (
        <div className={isLeagueSelected ?
            'row rounded-custom rounded-top-custom' :
            'row rounded-top-custom'}
             style={{
               marginBottom: '-10px',
               backgroundColor: '#ededed',
             }}
        >
          <div
              style={{display: 'flex', alignItems: 'center'}}
              className={!isLeagueSelected ? 'col-1' : 'col-1  bg-background'}>
            {value?.league_icon !== '' && <img
                height={isMobile ? 20 : 25}
                alt={value?.league_name || value?.name ||
                    selectedLeagueDate?.name}
                src={value?.league_new_icon?.replace('testcdn', 'cdn') ||
                    value?.new_icon?.replace('testcdn', 'cdn') ||
                    selectedLeagueDate?.icon}
                onError={addDefaultSrc}

            ></img>}
          </div>
          <div
              style={{display: 'flex', alignItems: 'center'}}
              className={isLeagueSelected ?
                  'col-5 p-2 sfProDisplayFontBold font-14 bg-background text-nowrap ' :
                  ' text-nowrap sfProDisplayFontBold font-14 col-5 p-2'}>
            {value?.league_name || value?.name || selectedLeagueDate?.name}
            {isLeagueSelected &&
                <Link to={'/football/matches'} onClick={handleClearParams}>
                  <FontAwesomeIcon icon={faWindowClose}
                                   size={'20'}
                                   className={'ms-3'}
                                   color={'green'}/>
                </Link>
            }
          </div>
        </div>
    );
  };

  return location.pathname?.includes('cricket') && isMobile ?
      renderCricketMobile()
      :
      (!selectedTeamType || (selectedTeamType && selectedTeamType === 'all')) &&
      (
          <div className={isLeagueSelected ? 'row rounded-custom' : 'row'}>
            <div
                style={{display: 'flex', alignItems: 'center'}}
                className={!isLeagueSelected ?
                    'col-1' :
                    'col-1  bg-background'}>
              {value?.league_icon !== '' && <img
                  height={isMobile ? 20 : 25}
                  alt={value?.league_name || value?.name ||
                      selectedLeagueDate?.name}
                  src={value?.league_new_icon?.replace('testcdn', 'cdn') ||
                      value?.new_icon?.replace('testcdn', 'cdn') ||
                      selectedLeagueDate?.icon}
                  onError={addDefaultSrc}
              ></img>}
            </div>
            <div
                style={{display: 'flex', alignItems: 'center'}}
                className={isLeagueSelected ?
                    'col-5 p-2 sfProDisplayFontBold font-14 bg-background' :
                    ' sfProDisplayFontBold font-14 col-5 p-2'}>
              {value?.league_name || value?.name || value?.date ||
                  selectedLeagueDate?.name}
              {isLeagueSelected &&
                  <Link to={'/football/matches'} onClick={handleClearParams}>
                    <FontAwesomeIcon icon={faWindowClose}
                                     size={'20'}
                                     className={'ms-3'}
                                     color={'green'}/>
                  </Link>
              }
            </div>
          </div>
      );
};

export default MatchItemLeague;
