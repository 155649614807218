import {getHostname} from "../CustomFunctions/getHostname";

export const ShopifyGraphQlQueries = (type, filter = null, id = null, data = {}) => {
    const hostName = window.location.hostname;
    const domainObject = getHostname(hostName);
    let target = domainObject.host;
    const location = window.location;
    if (target === 'localhost') {
        // target = 'gunners';
        target = 'celtic1967';
    }
    if (hostName === '127.0.0.1') {
        target = 'cfc-blues';
    }

    if(target === 'fanzine')
    {
        target=location?.pathname?.split('/')[1] || 'cfc-blues'
    }

    // filter = filter ? `, query: "${filter}"` : '';

    let BEST_SELLING = `{ collectionByHandle(handle: "${target}") { id products(first: 100, sortKey: ${filter}) { edges { node { tags id productType handle images(first: 1) { edges { node { src altText } } }
        options { name values } title variants(first: 100) { edges { node { id image { altText src } price {amount currencyCode} compareAtPrice {amount currencyCode} selectedOptions { name value } title } } } } } } }}`;

    let PRODUCT_LIST = '{\n' +
        '  collectionByHandle(handle: "' + target + '") {\n' +
        '    id\n' +
        '    products(first: 100'+data.sort_key+') {\n' +
        '      edges {\n' +
        '        node {\n' +
        '          tags\n' +
        '          id\n' +
        '          productType\n' +
        '          handle\n' +
        '          images(first: 1) {\n' +
        '            edges {\n' +
        '              node {\n' +
        '                src\n' +
        '                altText\n' +
        '              }\n' +
        '            }\n' +
        '          }\n' +
        '          options {\n' +
        '            name\n' +
        '            values\n' +
        '          }\n' +
        '          title\n' +
        '          variants(first: 100) {\n' +
        '            edges {\n' +
        '              node {\n' +
        '                id\n' +
        '                image {\n' +
        '                  altText\n' +
        '                  src\n' +
        '                }\n' +
        '              price  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '     compareAtPrice  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '                selectedOptions {\n' +
        '                  name\n' +
        '                  value\n' +
        '                }\n' +
        '                title\n' +
        '              }\n' +
        '            }\n' +
        '          }\n' +
        '        }\n' +
        '      }\n' +
        '    }\n' +
        '  }\n' +
        '}';

    let ALL = "{\n" +
        "  collections(first: 10) {\n" +
        "    edges {\n" +
        "      node {\n" +
        "        id\n" +
        "        title\n" +
        "      }\n" +
        "    }\n" +
        "  }\n" +
        "}";

    let PRODUCT_TYPES = '{\n' +
        '    productTypes(first: 100) {\n' +
        '        edges {\n' +
        '            node\n' +
        '        }\n' +
        '    }\n' +
        '}';

    let PRODUCT_TAGS = '{\n' +
        '    productTags(first: 100) {\n' +
        '        edges {\n' +
        '            node\n' +
        '        }\n' +
        '    }\n' +
        '}';

    let PRODUCT_DETAIL = '{\n' +
        '   productByHandle (handle: "'+id+'") {\n' +
        '               id\n' +
        '               title\n' +
        '               description\n' +
        '               descriptionHtml\n' +
        '               productType\n' +
        '               tags\n' +
        '               options {\n' +
        '                   name\n' +
        '                   values\n' +
        '               }\n' +
        '               images(first: 10) {\n' +
        '                   edges {\n' +
        '                       node {\n' +
        '                           id\n' +
        '                           src\n' +
        '                           altText\n' +
        '                       }\n' +
        '                   }\n' +
        '               }\n' +
        '               variants(first: 100) {\n' +
        '                   edges {\n' +
        '                       node {\n' +
        '                           id\n' +
        '                           title\n' +
        '              price  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '     compareAtPrice  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '                           image {\n' +
        '                               id\n' +
        '                               src\n' +
        '                               altText\n' +
        '                           }\n' +
        '                       }\n' +
        '                   }\n' +
        '               }\n' +
        '               priceRange {\n' +
        '                   maxVariantPrice {\n' +
    '                           amount\n' +
        '                   }\n' +
        '               }\n' +
        '           }\n' +
        '}\n';

    let GET_SHOPIFY_CART = 'query MyQuery {\n' +
        `  node(id :"${data.checkout_id}") {\n` +
        '    ... on Checkout {\n' +
        '      id\n' +
        '      webUrl\n' +
        '      completedAt\n' +
        '      discountApplications(first: 10) {\n' +
        '        edges {\n' +
        '          node {\n' +
        '            ... on DiscountCodeApplication {\n' +
        '              __typename\n' +
        '              code\n' +
        '            }\n' +
        '            value {\n' +
        '              ... on MoneyV2 {\n' +
        '                __typename\n' +
        '                amount\n' +
        '                currencyCode\n' +
        '              }\n' +
        '              ... on PricingPercentageValue {\n' +
        '                __typename\n' +
        '                percentage\n' +
        '              }\n' +
        '            }\n' +
        '          }\n' +
        '        }\n' +
        '      }\n' +
        '      lineItems(first: 100) {\n' +
        '        edges {\n' +
        '          node {\n' +
        '            id\n' +
        '             quantity\n' +
        '            title\n' +
        '            variant {\n' +
        '              id\n' +
        '              image {\n' +
        '                altText\n' +
        '                src\n' +
        '              }\n' +
        '    product{\n' +
        ' id\n' +
        '  handle}          title\n' +
        '              selectedOptions {\n' +
        '                name\n' +
        '                value\n' +
        '              }\n' +
        '          sku\n' +
        '    availableForSale\n' +
        '              price  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '            }\n' +
        '          }\n' +
        '        }\n' +
        '      }\n' +
        '     subtotalPrice  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '         totalPrice  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '         totalTax  {\n'+
        '               amount\n' +
        '         currencyCode\n' +
        '                    }\n' +
        '    }\n' +
        '  }\n' +
        '}\n';

    let APPLY_DISCOUNT_CODE = 'mutation MyMutation {\n' +
        `  checkoutDiscountCodeApply(checkoutId: "${data.checkout_id}", discountCode: "${data.discount_code}") {\n` +
        '    checkout {\n' +
        '       webUrl\n' +
        '      discountApplications(first: 10) {\n' +
        '        edges {\n' +
        '          node {\n' +
        '            ... on DiscountCodeApplication {\n' +
        '              __typename\n' +
        '              code\n' +
        '              value {\n' +
        '                ... on PricingPercentageValue {\n' +
        '                  __typename\n' +
        '                  percentage\n' +
        '                }\n' +
        '                ... on MoneyV2 {\n' +
        '                  __typename\n' +
        '                  amount\n' +
        '                  currencyCode\n' +
        '                }\n' +
        '              }\n' +
        '            }\n' +
        '          }\n' +
        '        }\n' +
        '      }\n' +
        '    }\n' +
        '    userErrors {\n' +
        '      message\n' +
        '    }'+
        '    checkoutUserErrors {\n' +
        '      code\n' +
        '      field\n' +
        '      message\n' +
        '    }' +
        '  }\n' +
        '}\n';

    let REMOVE_DISCOUNT_CODE = 'mutation MyMutation {\n' +
        `  checkoutDiscountCodeRemove(checkoutId: "${data.checkout_id}") {\n` +
        '    checkout {\n' +
        '       webUrl\n' +
        '    }\n' +
        '    userErrors {\n' +
        '      message\n' +
        '    }\n' +
        '    checkoutUserErrors {\n' +
        '      code\n' +
        '      field\n' +
        '      message\n' +
        '    }' +
        '  }\n' +
        '}';

    let CHECKOUT_CREATE = 'mutation MyMutation {\n' +
        `   checkoutCreate(input: {lineItems: {quantity: ${parseInt(data.variant_quantity)}, variantId: "${data.variant_id}"}}) {\n` +
        '    checkout {\n' +
        '      id\n' +
        '      webUrl\n' +
        '    }\n' +
        '  }\n' +
        '}\n';

    let CHECKOUT_LINE_ITEMS_ADD = 'mutation MyMutation {\n' +
        `  checkoutLineItemsAdd(checkoutId: "${data.checkout_id}", lineItems: {quantity: ${parseInt(data.variant_quantity)}, variantId: "${data.variant_id}"}) {\n` +
        '    checkout {\n' +
        '      webUrl\n' +
        '    }\n' +
        '    checkoutUserErrors {\n' +
        '      code\n' +
        '      field\n' +
        '      message\n' +
        '    }' +
        '  }\n' +
        '}\n';

    let CHECKOUT_LINE_ITEMS_UPDATE = 'mutation MyMutation {\n' +
        `  checkoutLineItemsUpdate(checkoutId: "${data.checkout_id}", lineItems: {id: "${data.linkItem_id}", quantity: ${parseInt(data.variant_quantity)}}) {\n` +
        '    checkout {\n' +
        '      webUrl\n' +
        '    }\n' +
        '  }\n' +
        '}\n';

    let CHECKOUT_LINE_ITEMS_REMOVE = 'mutation MyMutation {\n' +
        `  checkoutLineItemsRemove(checkoutId: "${data.checkout_id}", lineItemIds: "${data.lineItem_id}") {\n` +
        '    checkout {\n' +
        '      webUrl\n' +
        '    }\n' +
        '  }\n' +
        '}\n';

    let graphQuery;

    switch (type) {
        case "BEST_SELLING":
            graphQuery = {query: BEST_SELLING};
            break;
        case "PRODUCT_TYPES":
            graphQuery = {query: PRODUCT_TYPES}
            break;
        case "PRODUCT_TAGS":
            graphQuery = {query: PRODUCT_TAGS}
            break;
        case "PRODUCT_LIST":
            graphQuery = {query: PRODUCT_LIST}
            break;
        case "PRODUCT_DETAIL":
            graphQuery = {query: PRODUCT_DETAIL}
            break;
        case "GET_SHOPIFY_CART":
            graphQuery = {query: GET_SHOPIFY_CART}
            break;
        case "APPLY_DISCOUNT_CODE":
            graphQuery = {query: APPLY_DISCOUNT_CODE}
            break;
        case "CHECKOUT_CREATE":
            graphQuery = {query: CHECKOUT_CREATE}
            break;
        case "CHECKOUT_LINE_ITEMS_ADD":
            graphQuery = {query: CHECKOUT_LINE_ITEMS_ADD}
            break;
        case "CHECKOUT_LINE_ITEMS_REMOVE":
            graphQuery = {query: CHECKOUT_LINE_ITEMS_REMOVE}
            break;
        case "CHECKOUT_LINE_ITEMS_UPDATE":
            graphQuery = {query: CHECKOUT_LINE_ITEMS_UPDATE}
            break;
        case "REMOVE_DISCOUNT_CODE":
            graphQuery = {query: REMOVE_DISCOUNT_CODE}
            break;
        default:
            graphQuery = {query: ALL}
            break;
    }

    return graphQuery;
}
