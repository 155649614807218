import MatchItem from "./MatchItem";
import MatchItemLeague from "./MatchItemLeague";
import React, {useEffect, useState} from "react";
import {useLocation, useSearchParams} from 'react-router-dom';
import moment from "moment";

const Matches = (props) => {
    const [searchParams] = useSearchParams();
    let {
        isMobile,
        matchData,
        liveMatchData,
        selectedDate,
        setIsSingleMatchSelected,
        setSingleMatchData,
        leagueMatchesGroupedByDate,
        selectedLeagueDate,
        setSelectedLeagueData,
        setLeagueMatchesGroupedByDate,
        isLeagueSelected, setIsLeagueSelected,
        store, matchStates,
        selectedTeamType,
        isFanzineClubMatches,
    } = props;
    let [leagueID,] = useState(searchParams.get('league_id') || '')
    const location = useLocation()

    useEffect(() => {
    }, [searchParams.get('league_id'), leagueMatchesGroupedByDate]);
    useEffect(() => {

        console.log('store =',store?.misc?.appInit?.league_gs_id)

    }, [store]);

    useEffect(() => {
    }, [leagueMatchesGroupedByDate]);

    const addDefaultSrc = (ev) => {
        ev.target.src = require("../../../assets/images/default-league.png");
    };

    return (
        <div className={`col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 `}>
            <div className={`row`}>
                <div
                    className={`${(isMobile && selectedTeamType === 'all') ? 'pt-0 mt-0' : (isMobile && selectedTeamType !== 'all') ? 'pt-3 mt-3' : ''} mt-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                >
                    {
                        (!matchData && selectedTeamType === 'all') &&
                        <div className={'text-center'}>
                            No Matches Available {/*For {selectedTeamType.toUpperCase()} Team*/}
                        </div>
                    }
                    <div className={` ${(location.pathname.includes('cricket') && isMobile)&& 'p-3'} ${isMobile && 'pt-0 mt-0'} col-xxl-12 col-xl-12 col-lg-12`}>
                        {(leagueMatchesGroupedByDate == null) &&
                            matchData?.map((value, index) => {

                                if(isFanzineClubMatches && store?.misc?.appInit?.league_gs_id != value.league_id)
                                {
                                    return '';
                                }

                                return(
                                    value?.matches?.length ?
                                        (<div key={index}
                                              className="container p-1">
                                            {(searchParams.get('league_id') !=
                                                null && searchParams.get(
                                                    'league_id') ===
                                                value.league_id) ?
                                                <MatchItemLeague
                                                    selectedTeamType={selectedTeamType}
                                                    leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                                    isLeagueSelected={isLeagueSelected}
                                                    isMobile={isMobile}
                                                    setIsLeagueSelected={setIsLeagueSelected}
                                                    setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}
                                                    setSelectedLeagueData={setSelectedLeagueData}
                                                    addDefaultSrc={addDefaultSrc}
                                                    value={value}/>
                                                :
                                                ''
                                            }
                                            {searchParams.get('league_id') ==
                                                null &&
                                                <MatchItemLeague
                                                    selectedTeamType={selectedTeamType}

                                                    addDefaultSrc={addDefaultSrc}
                                                    value={value}
                                                    isLeagueSelected={isLeagueSelected}
                                                    isMobile={isMobile}
                                                    setIsLeagueSelected={setIsLeagueSelected}
                                                    leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                                    setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}/>
                                            }
                                            {value.matches.map(
                                                (matches, matchIndex) => (
                                                    (
                                                        (searchParams.get(
                                                                'league_id') !=
                                                            null &&
                                                            searchParams.get(
                                                                'league_id') ===
                                                            value.league_id) ?
                                                            <MatchItem
                                                                setSingleMatchData={setSingleMatchData}
                                                                liveMatchData={liveMatchData}
                                                                isMobile={isMobile}
                                                                setIsSingleMatchSelected={setIsSingleMatchSelected}
                                                                selectedDate={selectedDate}
                                                                matches={matches}
                                                                matchStates={matchStates}
                                                                isFanzineClubMatches={isFanzineClubMatches}
                                                                selectedTeamType={selectedTeamType}

                                                                matchIndex={matchIndex}/>
                                                            :
                                                            searchParams.get(
                                                                'league_id') ==
                                                            null ?
                                                                <MatchItem
                                                                    setSingleMatchData={setSingleMatchData}
                                                                    liveMatchData={liveMatchData}
                                                                    isMobile={isMobile}
                                                                    setIsSingleMatchSelected={setIsSingleMatchSelected}
                                                                    selectedDate={selectedDate}
                                                                    matchStates={matchStates}
                                                                    isFanzineClubMatches={isFanzineClubMatches}
                                                                    selectedTeamType={selectedTeamType}

                                                                    matches={matches}
                                                                    matchIndex={matchIndex}/> :
                                                                ''
                                                    )
                                                ))}
                                        </div>) : <></>
                                );
                            })}
                        {(leagueMatchesGroupedByDate != null) &&
                            leagueMatchesGroupedByDate?.map((value, index) => (
                                <>
                                    {index === 0 && <div className={'container p-1 '}>
                                        <MatchItemLeague addDefaultSrc={addDefaultSrc}
                                                         selectedTeamType={selectedTeamType}

                                                         value={store.football.fanzineLeagues.find(obj => {
                                                             return obj.league_id === leagueID
                                                         })}
                                                         isLeagueSelected={isLeagueSelected}
                                                         setIsLeagueSelected={setIsLeagueSelected}
                                                         selectedLeagueDate={selectedLeagueDate}
                                                         isMobile={isMobile}
                                                         leagueMatchesGroupedByDate={leagueMatchesGroupedByDate}
                                                         setLeagueMatchesGroupedByDate={setLeagueMatchesGroupedByDate}
                                        />
                                    </div>
                                    }
                                    <h6 className={'m-2'}>
                                        <b> {moment(value.date).format('dddd')} {'\t'} {moment(value.date).date()} {'\t'}
                                            {moment(value.date).format('MMMM')} {moment(value.date).year()}
                                        </b>
                                    </h6>
                                    {value.data.map((matches, matchIndex) => (
                                        (
                                            // console.log('matches =', matches)
                                            <MatchItem setSingleMatchData={setSingleMatchData}
                                                       liveMatchData={liveMatchData}
                                                       setIsSingleMatchSelected={setIsSingleMatchSelected}
                                                       selectedDate={selectedDate}
                                                       isMobile={isMobile}
                                                       matches={matches}
                                                       isFanzineClubMatches={true}
                                                       selectedTeamType={selectedTeamType}
                                                       matchIndex={matchIndex}/>
                                        )
                                    ))}
                                </>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Matches;
