import React, {useEffect, useState} from 'react';
import {FOOTBALL} from '../../Axios/EndPoints';
import {get} from '../../Axios/get';
import moment from 'moment-timezone';
import GoogleAd from '../Ads/GoogleAd';
import FanzineNewsCard from '../Cards/FanzineNewsCard';
import {useSearchParams} from 'react-router-dom';
import TopStoriesSkeleton from '../Skeleton/TopStoriesSkeleton';
import SkySportsAd from '../Ads/SkySportsAd';
import FanzineSportsNewsLayout
    from '../FanzineSports/NewsLayout/FanzineSportsNewsLayout';
import FanzineSportsNewsCard
    from '../FanzineSports/FanzineSportsNewsCard/FanzineSportsNewsCard';
import UpComingMatch from './Football/UpComingMatch';
import Podcasts from './PodcastSection/Podcasts';
import club from '../../Pages/FanzineSports/Club/Club';
import {CLUBS_MOBILE_BG} from '../../Configs/Targets';
import NewNewsFanzineCard from '../Cards/fanzine/newNewsCard';

const TopStories = (props) => {
    const [searchParams] = useSearchParams();
    const [topStories, setTopStories] = useState([]);
    const [topStoriesLoading, setTopStoriesLoading] = useState(true);

    const {isMobile, location, teamData, target,clubDetails, title } = props;

    const fetchTopStories = (isNew) => {

        let id = (searchParams.get('team_id') || searchParams.get('league_id') ||
            teamData?.clubId || '');
        let parameters = searchParams.get('league_id') ? '?league_id=' + id : teamData ? '?team_id=' + teamData?.clubId : '';

        let page = parameters === '' ? '?page=1' : '&page=1'
        let URL = FOOTBALL.LatestNewsEndPoint + parameters + page;


        get(URL).then((responseNews) => {
            let tempNewsData = [];
            responseNews?.data?.map((data) => {
                let news = {
                    news_id: data.news_id,
                    slug: data.slug,
                    news_image: data.image,
                    news_title: data.title,
                    news_url: data.url,
                    news_time: moment.tz(data.datetime, 'Europe/London')
                        .local()
                        .startOf('seconds')
                        .fromNow(),
                    news_date: moment(data.datetime).format('D MMM YYYY, H:mm'),
                    news_publisher: {
                        publisher_name: data.publisher.name,
                        publisher_icon: data.publisher.icon,
                    },
                    is_snack: data.is_snack,
                    main_team: data.main_team,
                    type: 'news',
                };
                tempNewsData.push(news);
            });

            if (isNew) {
                setTopStories(tempNewsData);
            } else {
                setTopStories([...topStories, ...tempNewsData]);
            }
            setTopStoriesLoading(false);
        }).catch((error) => console.log(error));
    };

    useEffect(() => {
        setTopStoriesLoading(true);
        fetchTopStories(true);
    }, [location?.pathname, teamData]);

    const renderTopStories = (News, isMobile, title) => {
        const SportNews = News;
        const Title = title;


        const renderLeftNewsMobile = () =>{

                const MobileBG = CLUBS_MOBILE_BG[target];

            return (
                <div
                    className={`container pb-3`}>
                    <div className={'bebasFont font-weight-600 font-25 container'}>
                        {Title}
                    </div>
                    <div className={'row'}>
                        <div
                            className={`${isMobile ? 'p-0' : 'mt-5 p-1'} m-0 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12`}
                        >
                            <div >
                                {
                                    isMobile && SportNews &&
                                    SportNews?.map((news,index)=>{

                                        const isBigImage = index ===0;

                                        return  isBigImage ?
                                            (
                                                <NewNewsFanzineCard
                                                    key={index}
                                                    news={news}
                                                    isMobile={isMobile}
                                                    showNewsSide={false}
                                                />
                                            )
                                            : (
                                                <NewNewsFanzineCard
                                                    key={index}
                                                    news={news}
                                                    isMobile={isMobile}
                                                    showNewsSide={true}
                                                />)
                                    })}
                            </div>
                            {isMobile && target && clubDetails && <UpComingMatch/>}
                            {isMobile && target && clubDetails && <Podcasts data={clubDetails} isMobile={isMobile}
                                                                            target={target}/>}

                            {
                                !isMobile &&
                                <FanzineNewsCard
                                news={SportNews[0]}
                                imageHeight={'400px'}
                                cardTextSize={''}
                                isNewsPage={''}
                                publisherIconSize={''}
                                isMain={''}
                                isMobileAppDesign={true}
                            />
                            }                        </div>
                        <div
                            className={'col-lg-4 mt-4 m-0 p-1 col-xl-4 col-md-4 col-sm-12 col-xs-12'}>
                            {isMobile &&
                                <NewNewsFanzineCard
                            news={SportNews[1]}
                            isMobile={isMobile}
                            showNewsSide={true}
                        />

                            }
                            {!isMobile && <FanzineNewsCard
                                news={SportNews[1]}
                                imageHeight={'245px'}
                                cardTextSize={''}
                                isNewsPage={''}
                                publisherIconSize={''}
                                isMain={''}
                            />
                            }
                            {isMobile &&                                 <NewNewsFanzineCard
                                news={SportNews[2]}
                                isMobile={isMobile}
                                showNewsSide={true}
                            />
                            }
                            {!isMobile && <FanzineNewsCard
                                news={SportNews[2]}
                                imageHeight={'245px'}
                                cardTextSize={''}
                                isNewsPage={''}
                                publisherIconSize={''}
                                isMain={''}
                            />
                            }                        </div>
                    </div>
                    <div className={isMobile ? 'w-100' : 'container'}>
                        <div className={`row ${isMobile ? '' : 'd-flex justify-content-center'}`}>
                            <div
                                className={`mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                            >
                                <SkySportsAd/>
                            </div>
                            <div
                                className={'col-lg-4 mt-3 col-xl-4 col-md-4 col-sm-12 col-xs-12'}>
                                {isMobile &&
                                    <NewNewsFanzineCard
                                    news={SportNews[3]}
                                    isMobile={isMobile}
                                    showNewsSide={true}
                                />
                                }
                                {!isMobile && <FanzineNewsCard
                                    news={SportNews[3]}
                                    imageHeight={'245px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />}
                                {isMobile && <NewNewsFanzineCard
                                    news={SportNews[4]}
                                    isMobile={isMobile}
                                    showNewsSide={true}
                                />
                                }
                                {!isMobile && <FanzineNewsCard
                                    news={SportNews[4]}
                                    imageHeight={'245px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />
                                }                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        const renderLeftNews = () => {

            return (
                <div
                    className={'container pb-3'}>
                    <div className={'robotoCondensedBold font-28 container'}>
                        {Title}
                    </div>
                    <div className={'row'}>
                        <div
                            className={`mt-4 m-0 p-1 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12`}
                        >
                            <FanzineNewsCard
                                news={SportNews[0]}
                                imageHeight={isMobile ? '245px' : '500px'}
                                cardTextSize={''}
                                isNewsPage={''}
                                publisherIconSize={''}
                                isMain={''}
                            />
                        </div>
                        <div
                            className={'col-lg-4 mt-4 m-0 p-1 col-xl-4 col-md-4 col-sm-12 col-xs-12'}>
                            <FanzineNewsCard
                                news={SportNews[1]}
                                imageHeight={'245px'}
                                cardTextSize={''}
                                isNewsPage={''}
                                publisherIconSize={''}
                                isMain={''}
                            />
                            <FanzineNewsCard
                                news={SportNews[2]}
                                imageHeight={'245px'}
                                cardTextSize={''}
                                isNewsPage={''}
                                publisherIconSize={''}
                                isMain={''}
                            />
                        </div>
                    </div>
                    <div className={isMobile ? 'w-100' : 'container'}>
                        <div className={`row ${isMobile ? '' : 'd-flex justify-content-center'}`}>
                            <div
                                className={`mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12`}
                            >
                                <SkySportsAd/>
                            </div>
                            <div
                                className={'col-lg-4 mt-3 col-xl-4 col-md-4 col-sm-12 col-xs-12'}>
                                <FanzineNewsCard
                                    news={SportNews[3]}
                                    imageHeight={'245px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />
                                <FanzineNewsCard
                                    news={SportNews[4]}
                                    imageHeight={'245px'}
                                    cardTextSize={''}
                                    isNewsPage={''}
                                    publisherIconSize={''}
                                    isMain={''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );

        };

        const renderRightAds = () => {
            let numberOfAds = [1, 2, 3];
            return (
                <div className={'m-0 p-0 col-4'}>
                    <div className={`row container`}>
                        {numberOfAds.map((news, index) => {

                            return (index < 4) && (
                                <div
                                    className={`${index === 0 &&
                                    'mt-5 pt-4'} col-xl-12 m-0 p-1 col-lg-12 col-md-12 col-sm-12 col-xs-12`}
                                >
                                    <div
                                        style={{
                                            border: '1px black solid',
                                        }}
                                        className="d-flex justify-content-center align-items-center h1">
                                        <GoogleAd id={'4009154580'}/>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>);
        };

        const renderMain = () => {

            return (
                <section className={` ${isMobile && 'pt-5'} w-100`}>
                    {!topStoriesLoading && topStories?.length > 0 && !isMobile && renderLeftNews()}
                    {!topStoriesLoading && topStories?.length > 0 && isMobile && renderLeftNewsMobile()}
                    {topStoriesLoading && <TopStoriesSkeleton/>}
                    {/*{!isMobile && renderRightAds()}*/}
                </section>
            );

        };

        return renderMain();
    };

    return renderTopStories(topStories, isMobile, title ? title : 'Latest News');

};

export default TopStories;
